<template>
  <div
    class="field"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>

    <!-- Pensionsberättigad -->
    <!-- Arbetsgivare -->
    <!-- ID -->
    <!-- Datum -->
    <!-- Typ -->
    <!-- Belopp -->

    <p v-if="data" class="border">
      <!-- {{ data }} -->
      <table class="no-padding">
        <tr>
          <td>ID</td>
          <td>{{ data['ShortID'] }}</td>
        </tr>
        <tr>
          <td>Pensionsberättigad</td>
          <td>{{ data['PensionablePersonalNumber'] }} - {{ data['PensionableName'] }}</td>
        </tr>
        <tr>
          <td>Arbetsgivare</td>
          <td>{{ data['EmployerShortID'] }} - {{ data['EmployerName'] }}</td>
        </tr>
        <tr>
          <td>Datum</td>
          <td>{{ data['Issued'].substring(0, 10) }}</td>
        </tr>
        <tr>
          <td>Typ</td>
          <td>{{ data['AccountName'] }}</td>
        </tr>
        <tr>
          <td>Belopp</td>
          <td>{{ formatMoney(data['PensionCapital']) }}</td>
        </tr>
      </table>
    </p>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'field-many-to-one',
  props: {
    error: String,
    grid: Object,
    id: String,
    value: String,
    relatedto: String,
    relatedtocommon: Boolean
  },
  data() {
    return {
      uniqueid: _.uniqueId('field-info-' + this.id + '-' + this.relatedto),
      data: null
    }
  },
  methods: {
    refresh(id) {
      this.singleProvider({ filters: new Map([['ID', [this.value]]]) }).then(res => {
        this.data = res.Data
      })
    },
    formatMoney(value) {
      const amount = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
        value
      )
      return amount
    }
  },
  computed: {
    info() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.info(this.relatedto)
    },
    apiClient() {
      if (this.$store.getters.api === null) {
        return null
      }
      if (this.relatedtocommon) {
        return this.$store.getters.api.common()
      } else {
        return this.$store.getters.api.trust(this.$route.params.trust)
      }
    },
    singleProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.singleProvider(this.relatedto)
    }
  },
  watch: {
    value() {
      if (this.value !== '') {
        this.refresh(this.value)
      }
    }
  },
  mounted() {
    if (this.value && this.value !== '') {
      this.refresh(this.value)
    }
  }
}
</script>

<style scoped>
button {
  color: white;
  background-color: #ff6c6c;
  border-radius: 6px;
  border: 0;
  margin-left: 1rem;
}

table.no-padding td {
  padding: 0;
  margin: 0;
}

.border {
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  box-shadow: inset 0 1px 2px #e6e6e6;
  /* font-size: 0.9rem; */
  padding: 0.5rem;
}
</style>
