import moment from 'moment'

export default class GraphQL {
  constructor(client) {
    this.client = client
    this.abortcontrollers = new Map()
  }

  async fields(type) {
    const fieldsResponse = await this.client.get(`entity/${type}/fields`)

    var fields = []
    fieldsResponse.data.forEach(section => {
      fields = fields.concat(section.Fields)
    })

    return fields
  }

  async transmuteFields(type) {
    const fieldsResponse = await this.client.get(`entity/${type}/transmute-fields`)

    var fields = []
    fieldsResponse.data.forEach(section => {
      fields = fields.concat(section.Fields)
    })

    return fields
  }

  fieldResolver(fields) {
    return key => {
      var type = null
      fields.forEach(field => {
        if (field.Key === key) {
          type = field
        }
      })
      return type
    }
  }

  filterQueryableFields(fields) {
    return fields.filter(field => !['one-to-many', 'polymorphic', 'many-to-many'].includes(field.Type))
  }

  /**
   * Default Query:
   * {
   *   sortBy: "ID"   // ( string )
   *   sortDesc: true // ( bool )
   *   search: "",     // ( string )                   Search
   *   filters: {     // ( map[string][]interface{} ) Filter results
   *     "ID": ["123"]
   *   },
   * }
   */
  query(type, query) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const fields = await this.fields(type)
      const queryableFields = this.filterQueryableFields(fields)
      const fieldResolver = this.fieldResolver(fields)

      var filters = []
      var params = []
      var variables = {}

      query.filters = query.filters || new Map()

      query.filters.forEach((value, key) => {
        const field = fieldResolver(key)

        switch (field !== null ? field.Type : null) {
          case 'bool':
            filters.push(`${key}: $${key}`)
            params.push(`$${key}: [Boolean]`)
            variables[key] = value
            break
          default:
            filters.push(`${key}: $${key}`)
            params.push(`$${key}: [String]`)
            variables[key] = value
            break
        }
      })

      if (query.selected) {
        filters.push('_Selected: $_Selected')
        params.push('$_Selected: String')
        variables._Selected = query.selected
      }

      if (query.allTrusts && query.allTrusts === true) {
        filters.push('_AllTrusts: $_AllTrusts')
        params.push('$_AllTrusts: Boolean')
        variables._AllTrusts = true
      }

      if (query.searchOptions?.IgnoredSearchFields && query.searchOptions.IgnoredSearchFields.length > 0) {
        filters.push('_IgnoreFields: $_IgnoreFields')
        params.push('$_IgnoreFields: [String]')
        variables._IgnoreFields = query.searchOptions.IgnoredSearchFields
      }

      if (query.searchOptions?.DisableStrictNumericSearch) {
        filters.push('_DisableStrictNumericSearch: $_DisableStrictNumericSearch')
        params.push('$_DisableStrictNumericSearch: Boolean')
        variables._DisableStrictNumericSearch = query.searchOptions.DisableStrictNumericSearch
      }

      if (query.skipComputedFields && query.skipComputedFields === true) {
        filters.push('_SkipComputedFields: $_SkipComputedFields')
        params.push('$_SkipComputedFields: Boolean')
        variables._SkipComputedFields = true
      }

      if (query.limit && query.limit !== 0) {
        filters.push('_Limit: $_Limit')
        params.push('$_Limit: Int')
        variables._Limit = query.limit
      }

      if (query.search && query.search !== '') {
        filters.push('_Search: $_Search')
        params.push('$_Search: String')
        variables._Search = query.search
      }

      if (query.after && query.after !== '') {
        filters.push('_After: $_After')
        params.push('$_After: String')
        variables._After = query.after
      }

      if (query.sortBy && query.sortBy !== '') {
        filters.push('_SortBy: $_SortBy')
        params.push('$_SortBy: String')
        variables._SortBy = query.sortBy

        filters.push('_SortDesc: $_SortDesc')
        params.push('$_SortDesc: Boolean')
        variables._SortDesc = !!query.sortDesc
      }

      var filterString = ''
      if (filters.length > 0) {
        filterString = `(${filters.join(', ')})`
      }

      var paramsString = ''
      if (params.length > 0) {
        paramsString = `(${params.join(', ')})`
      }

      var joinedQuery = queryableFields
        .map(field => {
          let k = field.Key
          if (field.SubFields) {
            k += ` { ${field.SubFields.map(subField => subField.Key).join(' ')} } `
          }
          return k
        })
        .join(' ')

      const axiosBody = {
        query: `query Fetch ${paramsString} { Results: ${type}s ${filterString} { PageInfo {After Before} Results { Meta { ValidationErrors { Key Message } } Data {${joinedQuery} } } } }`,
        variables: variables
      }
      const axiosOpts = {}

      let abortControllerFinished = () => {}
      if (query.uniqueid) {
        let abortcontroller = null
        ;[abortcontroller, abortControllerFinished] = this.onlyOneRequest(query.uniqueid)
        axiosOpts.signal = abortcontroller.signal
      }

      this.client
        .post(`graphql?_type=${type}&_uniq=${query.uniqueid || '-'}`, axiosBody, axiosOpts)
        .then(resp => {
          resolve(resp.data.data.Results)
        })
        .catch(err => {
          if (err.response && typeof err.response.data === 'object') {
            return reject(new Error(err.response.data.error))
          }
          reject(err)
        })
        .finally(abortControllerFinished)
    })
  }

  onlyOneRequest(uniqueid) {
    const existing = this.abortcontrollers.get(uniqueid)
    if (existing) {
      existing.abort('new request for ' + uniqueid)
    }
    const controller = new AbortController()
    this.abortcontrollers.set(uniqueid, controller)
    return [
      controller,
      () => {
        this.abortcontrollers.delete(uniqueid)
      }
    ]
  }

  isEmpty(val) {
    return val === undefined || val === null || val === ''
  }

  /**
   */
  mutate(type, mutation, data = {}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      var fields
      if (mutation.startsWith('transmute')) {
        fields = await this.transmuteFields(type)
      } else {
        fields = await this.fields(type)
      }
      const queryableFields = this.filterQueryableFields(fields)

      var variables = {}
      var args = []
      var params = []

      // const isDependencyMet = (field, data) => {
      //   if (field.Dependencies) {
      //     for (let i = 0; i < field.Dependencies.length; i++) {
      //       const dep = field.Dependencies[i]
      //       if (data[dep.Field] !== dep.Value) {
      //         return false
      //       }
      //     }
      //   }
      //   return true
      // }

      fields.forEach(field => {
        if (
          data[field.Key] === undefined ||
          (field.ReadOnly && (!field.Required || !this.isEmpty(data.ID)) && !field.IsPrimary)
        ) {
          return
        }

        let value = data[field.Key]

        if (field.Type === 'date' && value !== null) {
          value = moment(value).format('YYYY-MM-DD')
        }

        switch (field.Type) {
          case 'bool':
            args.push(`${field.Key}: $${field.Key}`)
            params.push(`$${field.Key}: ${field.Required ? 'Boolean!' : 'Boolean'}`)
            variables[field.Key] = value
            break
          case 'int':
            args.push(`${field.Key}: $${field.Key}`)
            params.push(`$${field.Key}: ${field.Required ? 'Int!' : 'Int'}`)
            variables[field.Key] = Number(value)
            break
          case 'date':
          case 'money':
          case 'decimal':
          case 'text':
          case 'string':
          case 'many-to-one':
          case 'datetime':
          case 'state':
            args.push(`${field.Key}: $${field.Key}`)
            params.push(`$${field.Key}: ${field.Required ? 'String!' : 'String'}`)
            variables[field.Key] = value
            break
          default:
            break
        }
      })

      var argsString = ''
      if (args.length > 0) {
        argsString = `(${args.join(', ')})`
      }

      var paramsString = ''
      if (params.length > 0) {
        paramsString = `(${params.join(', ')})`
      }

      var joinedQuery = queryableFields
        .map(field => {
          let k = field.Key
          if (field.SubFields) {
            k += ` { ${field.SubFields.map(subField => subField.Key).join(' ')} } `
          }
          return k
        })
        .join(' ')

      this.client
        .post('graphql', {
          query: `mutation Mutate ${paramsString} { result: ${mutation} ${argsString} { Meta { ValidationErrors { Key Message } DisabledFields } Data {${joinedQuery} } } }`,
          variables: variables
        })
        .then(resp => {
          resolve(resp.data.data.result)
        })
        .catch(err => {
          if (err.response && typeof err.response.data === 'object') {
            return reject(err.response.data)
          }
          reject(err)
        })
    })
  }
}
