<template>
  <div class="dynamic-table" @scroll="onScroll" style="overflow-y: auto" tabindex="-1">
    <div class="message-container" v-if="fault">
      <span style="font-size: 20px; color: rgb(255, 108, 108)" v-if="fault">{{ fault }}</span>
    </div>
    <table :class="loading === true ? 'loading' : ''" v-if="columns">
      <thead>
        <tr class="sortable">
          <th
            v-for="field in sortedColumns"
            :name="field.Key"
            v-bind:key="field.Key"
            v-on:click="sorting && toggleSort(field)"
            :colspan="columnCount(field)"
            :class="{
              sort: sortBy === getSortKey(field),
              'sort--desc': sortDesc,
              'sort--asc': !sortDesc,
              sortable: sortables.has(field.Key)
            }"
          >
            {{ field.TableTitle || field.Title }}
            <font-awesome-icon
              v-if="sortBy === getSortKey(field)"
              :icon="['fas', sortDesc ? 'caret-down' : 'caret-up']"
            />
          </th>
          <th></th>
        </tr>
        <tr class="filter" v-if="filtering">
          <th v-for="field in sortedColumns" v-bind:key="field.Key" :colspan="columnCount(field)">
            <div v-if="filterables.has(field.Key)">
              <div class="filter-container">
                <span
                  :class="[
                    'filter-bubble',
                    value.length > 0 && value[0] === '!' ? 'filter-bubble--red' : 'filter-bubble--green'
                  ]"
                  v-for="{ i: i, value: value } in getColumnFilters(field.Key)"
                  v-bind:key="i"
                >
                  <select
                    v-if="field.Choices"
                    :ref="`filter${field.Key}${i}`"
                    :value="value"
                    @input="changeFilter(field.Key, i, $event.target.value)"
                    v-on:click.stop="() => {}"
                    style="background: transparent;border: none;"
                  >
                    <option
                      v-for="{ Value, Title } in field.Choices.filter(f => !f.ExcludeFromFilter)"
                      :value="Value"
                      :key="Value"
                      >{{ Title }}</option
                    >
                  </select>
                  <input
                    v-else
                    :type="field.Type == 'date' ? 'date' : 'text'"
                    :ref="`filter${field.Key}${i}`"
                    :value="value"
                    @input="changeFilter(field.Key, i, $event.target.value)"
                    v-on:click.stop="() => {}"
                    v-autowidth="{ maxWidth: 'none', minWidth: '16px', comfortZone: 2 }"
                  />

                  <button
                    type="button"
                    class="button--unstyled"
                    style="color: #8D9589"
                    v-on:click.stop="removeFilter(field.Key, i)"
                  >
                    <font-awesome-icon :icon="['far', 'times-circle']" fixed-width />
                  </button>
                </span>
              </div>
              <button
                type="button"
                v-on:click="addFilter(field.Key)"
                class="button-add-filter button--unstyled"
                style="color: var(--deep-sapphire)"
              >
                <font-awesome-icon :icon="['far', 'plus-circle']" fixed-width />
              </button>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in rows"
          v-bind:key="row[primaryKeyField.Key]"
          v-on:click.stop="onRowClick ? onRowClick(row[rowLink.key], rowLink.type) : () => {}"
          :style="{ 'background-color': rowColor(row), cursor: onRowClick ? 'pointer' : 'auto' }"
        >
          <template v-for="field in sortedColumns">
            <td
              v-for="(column, i) in getColumns(field, row)"
              v-bind:key="field.Key + i"
              :class="
                `${field.Type === 'money' ? 'has-text-right' : ''}
              ${fieldTableClasses(field, column)}
              expandable`
              "
            >
              <span v-if="column === null" style="color: gray;font-family: monospace;">nil</span>
              <span v-else-if="column === undefined" style="color: gray;font-family: monospace;">-</span>
              <span v-else-if="field.Key === 'Split' && row['Priority']"
                >{{ column }}
                <span class="split-percentage">({{ splitPercentage(column, row['Priority']) }})</span></span
              >
              <span v-else-if="field.Type === 'diff'" v-html="jsonDiff(column[0], column[1])"></span>
              <span
                v-else-if="
                  field.Type === 'bool' ||
                    (field.Type === 'string' && field.Choices) ||
                    (field.Type === 'state' && field.Choices)
                "
                >{{ (field.Choices.filter(c => c.Value === column)[0] || { Title: column }).Title }}</span
              >
              <span v-else-if="field.Type === 'html'" v-html="column" />
              <span v-else-if="field.Type === 'file'">
                <a class="button is-primary" target="_BLANK" :href="column" @click.stop="() => {}">Öppna</a>
              </span>
              <span v-else-if="field.Type === 'datetime'">
                {{ column | moment('YYYY-MM-DD HH:mm:ss') }}<br />
                <small>{{ column | moment('from', 'now') }}</small>
              </span>
              <span v-else-if="field.Type === 'date'"> {{ column | moment('YYYY-MM-DD') }}<br /> </span>
              <span v-else-if="field.Type === 'money'">
                <span
                  :style="{
                    'background-color': parseFloat(column) > 0 ? '#E9FAE5' : '#FAE5E5',
                    padding: '4px 7px',
                    'border-radius': '7px',
                    'font-family': 'DroidSansMonoSlashed'
                  }"
                >
                  <i18n-n :value="parseFloat(column)" format="currency" locale="sv-SE">
                    <template v-slot:currency="slotProps"
                      ><span style="color: #aaa">{{ slotProps.currency }}</span></template
                    >
                    <template v-slot:fraction="slotProps"
                      ><span style="color: #aaa;">{{ slotProps.fraction }}</span></template
                    >
                    <template v-slot:decimal="slotProps"
                      ><span style="color: #aaa;">{{ slotProps.decimal }}</span></template
                    >
                  </i18n-n>
                </span>
                <template v-if="amountOfDecimals(column) > 2">
                  <br />
                  <span style="color: #f28800;font-style: italic;font-size: 10px;">Observera avrundning</span>
                </template>
              </span>
              <span v-else-if="field.Type === 'array-table'"> {{ toArrayTable(row, field) }}</span>
              <span v-else-if="field.Mask === 'internal-id'" style="font-family: monospace">
                {{ column }}
              </span>
              <span v-else-if="field.Mask === 'ssn'" style="font-family: monospace">
                {{ column.substring(0, 8) }}-{{ column.substring(8, 12) }}
              </span>
              <span v-else-if="field.Mask === 'orgno'" style="font-family: monospace">
                {{ column.substring(0, 6) }}-{{ column.substring(6, 10) }}
              </span>
              <span v-else-if="i == 0 && field.Key == 'Employers'" style="font-family: monospace">{{ column }}</span>
              <span v-else :class="field.Class">{{ column }}</span>
            </td>
          </template>
          <td
            v-if="editRow"
            class="has-text-right"
            :style="{
              width: '5rem'
            }"
          >
            <button class="button is-small" type="button" @click.stop="() => $emit('edit', row[primaryKeyField.Key])">
              Ändra
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td :colspan="totalColumnCount" class="table-status-bar">
            <div>
              <span v-if="fetching" class="loading-spinner"
                ><font-awesome-icon :icon="['fal', 'spinner-third']" fixed-width
              /></span>
              <span v-else-if="fault">{{ fault }}</span>
              <button v-else-if="pageInfo && pageInfo.After" @click="fetchMore()" class="button is-small">
                Hämta fler
              </button>
              <!-- <span v-else>Alla resultat hämtade</span> -->
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
    <button v-if="showCreateButton && fullHeight" class="button-add-new fixed" type="button" @click="$emit('create')">
      Skapa nytt
    </button>
    <button v-if="showCreateButton && !fullHeight" class="button-add-new-inline" type="button" @click="$emit('create')">
      <slot name="add-new-button">+ Lägg till</slot>
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'
import * as jsondiffpatch from 'jsondiffpatch'

import 'jsondiffpatch/dist/formatters-styles/html.css'

export default Vue.extend({
  name: 'dynamicTable',
  props: {
    fullHeight: {
      type: Boolean,
      default: false
    },
    fieldsProvider: {
      type: Function,
      required: true
    },
    listProvider: {
      type: Function,
      required: true
    },
    sorting: {
      type: Boolean,
      default: true
    },
    filtering: {
      type: Boolean,
      default: true
    },
    showCreateButton: {
      type: Boolean,
      default: true
    },
    onRowClick: {
      type: Function,
      default: null
    },
    hiddenFields: {
      type: Array,
      default: () => []
    },
    allTrusts: {
      type: Boolean,
      default: false
    },
    linkKey: {
      type: String,
      default: null
    },
    editRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uniqueid: _.uniqueId('dynamic-table'),
      rows: [],
      columns: [],
      primaryKeyField: null,
      rowLinkField: null,
      loading: true,
      fetching: false,
      filtersChangeTracker: 1,
      filters: new Map(),
      sortBy: null,
      sortDesc: false,
      pageInfo: null,
      fault: null,
      manyToOneColumnsResults: {},
      refresh: _.debounce(() => {
        this.refreshNow()
      }, 700),
      refreshManyToOneColumnsResults: _.debounce(() => {
        this.refreshManyToOneColumnsResultsNow()
      }, 100)
    }
  },
  shortcuts: {
    reload(e) {
      e.preventDefault()
      this.loading = true
      this.refresh()
    }
  },
  methods: {
    resetError() {
      this.fault = null
    },
    handleError(err) {
      if (axios.isCancel(err)) {
        // Do nothing
      } else if (err instanceof Error) {
        if (err.name === 'Error') {
          err.name = 'Varning'
        }
        this.fault = err.toString()
      } else if (err.error && typeof err.error === 'string') {
        this.fault = err.error
      } else {
        this.fault = 'Något gick fel'
      }
    },
    jsonDiff(left, right) {
      if (left === '') {
        left = 'null'
      }
      if (right === '') {
        right = 'null'
      }
      left = JSON.parse(left)
      right = JSON.parse(right)
      const delta = jsondiffpatch.diff(left, right)
      return jsondiffpatch.formatters.html.format(delta, left)
    },
    addFilter(column) {
      if (!this.filterables.has(column)) {
        return
      }

      const columnFilters = this.filters.get(column) || []
      columnFilters.push('')
      this.filters.set(column, columnFilters)

      // Workaround to get correct Vue reactivity
      this.filtersChangeTracker++

      const newIndex = columnFilters.length - 1
      this.$nextTick(() => this.$refs[`filter${column}${newIndex}`][0].focus())
    },
    removeFilter(column, i) {
      const columnFilters = this.filters.get(column) || []
      columnFilters.splice(i, 1)
      if (columnFilters.length === 0) {
        this.filters.delete(column)
      } else {
        this.filters.set(column, columnFilters)
      }

      // Workaround to get correct Vue reactivity
      this.filtersChangeTracker++

      this.loading = true
      this.refresh()
    },
    changeFilter(column, i, value) {
      const columnFilters = this.filters.get(column) || []
      columnFilters[i] = value
      this.filters.set(column, columnFilters)

      // Workaround to get correct Vue reactivity
      this.filtersChangeTracker++

      this.loading = true
      this.refresh()
    },
    getColumnFilters(column) {
      return this.filtersChangeTracker && (this.filters.get(column) || []).map((value, i) => ({ i: i, value: value }))
    },
    getColumns(field, row) {
      if (field.Type === 'many-to-one') {
        return this.manyToOneColumns(field, row[field.Key])
      } else if (field.Type === 'one-to-many' || field.Type === 'polymorphic' || field.Type === 'many-to-many') {
        return this.oneToManyColumns(field, row)
      }
      return [row[field.Key]]
    },
    fieldTableClasses(field, column) {
      const fullLengthFields = ['Email', 'AssistantEmail']
      if (fullLengthFields.includes(field.Key)) return ''

      let result = ''
      if (['Employers', 'EmployerID', 'AdvisorAgreements'].includes(field.Key)) {
        // check if int, then return w-50 else w-150
        if (Number.isInteger(Number(column))) {
          result = 'w-75'
        } else {
          result = 'w-150'
        }
      } else if (['PensionAgeFrom', 'PensionAgeTo'].includes(field.Key)) {
        result = 'w-50'
      } else if (field.Key === 'AccountID') {
        result = 'w-100'
      } else if (['bool', 'state', 'decimal', 'date'].includes(field.Type) || ['Fnr', 'Number'].includes(field.Key)) {
        result = 'w-100'
      } else if (['string', 'orgno'].includes(field.Type) || !field.Key === 'Fnr') {
        result = 'w-150'
      } else if (field.Type === 'datetime') {
        result = 'w-200'
      }

      return result
    },
    toggleSort(field) {
      const column = this.getSortKey(field)
      if (!this.sortables.has(field.Key) && !this.sortables.has(field.SortKey)) {
        return
      }
      if (this.sortBy !== column) {
        this.sortBy = column
      } else {
        this.sortDesc = !this.sortDesc
      }
    },
    refreshNow(after = null) {
      const request = this.providerCtx
      if (after !== null) {
        request.after = after
      }
      this.fetching = true
      this.listProvider(request)
        .then(({ Results, PageInfo }) => {
          this.pageInfo = PageInfo
          if (after !== null) {
            Results.forEach(row => {
              const data = row.Data
              data._Meta = row.Meta
              this.rows.push(data)
            })
          } else {
            this.rows = Results.map(row => {
              const data = row.Data
              data._Meta = row.Meta
              return data
            })
          }
          this.loading = false
          this.resetError()
        })
        .catch(err => {
          this.handleError(err)
        })
        .finally(() => {
          this.fetching = false
        })
    },
    refreshColumns() {
      this.sortBy = null
      this.sortDesc = false
      this.loading = true

      this.filters = new Map()
      this.manyToOneColumnsResults = {}
      this.filtersChangeTracker++

      this.fieldsProvider({ cancelToken: this.fullHeight ? 'fieldsProvider' : null, allTrusts: true })
        .then(({ Sections, Entity }) => {
          if (this.sortBy === null && Entity.DefaultSortBy !== '') {
            this.sortBy = Entity.DefaultSortBy
            this.sortDesc = Entity.DefaultSortDesc
          }
          this.groups = Sections
          this.columns = []
          this.groups.forEach(section => {
            section.Fields.forEach(field => {
              if (field.IsPrimary) {
                this.primaryKeyField = field
              }
              if (this.linkKey === field.Key) {
                this.rowLinkField = field
              }
              if (this.hiddenFields.indexOf(field.Key) === -1 && (field.Show || field.ShowInTable)) {
                this.columns.push(field)
              }

              if (field.DefaultFilters && field.DefaultFilters.length > 0) {
                this.filters.set(field.Key, field.DefaultFilters)
              }
            })
          })
          this.$nextTick(this.refreshNow)
        })
        .catch(err => {
          this.loading = false
          this.handleError(err)
        })
    },
    fetchMore() {
      this.refreshNow(this.pageInfo.After)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight && this.pageInfo && this.pageInfo.After && !this.fetching) {
        this.fetchMore()
      }
    },
    rowColor(row) {
      if (row.State && this.stateColors.has(row.State)) {
        return this.stateColors.get(row.State)
      }

      if (
        [
          row.Active,
          this.hiddenFields.indexOf('EmployerActive') === -1 ? row.EmployerActive : undefined,
          this.hiddenFields.indexOf('PensionableActive') === -1 ? row.PensionableActive : undefined
        ].includes(false)
      ) {
        return 'rgba(0, 0, 0, 0.25)'
      }
      if (row._Meta.ValidationErrors.length > 0) {
        return '#FAE5E5'
      }
      if (row.Warnings && row.Warnings.length > 0) {
        return 'rgba(255, 247, 0, 0.25)'
      }
      return 'white'
    },
    amountOfDecimals(value) {
      if (!value) {
        return 0
      }
      return (
        Number(value)
          .toString()
          .split('.')[1] || ''
      ).length
    },
    splitPercentage(split, priority) {
      var totalSplit = 0
      this.rows.forEach(row => {
        if (row.Priority === priority) {
          totalSplit += parseFloat(row.Split)
        }
      })
      return parseFloat(((split / totalSplit) * 100).toFixed(3)) + '%'
    },
    columnCount(field) {
      if (field.Type === 'many-to-one') {
        switch (field.RelatedTo) {
          case 'Employer':
          case 'Pensionable':
          case 'Advisor':
            return 2
        }
      } else if (field.Type === 'many-to-many') {
        switch (field.RelatedTo) {
          case 'Employer':
          case 'Advisor':
            return 2
        }
      }
      return 1
    },
    manyToOneColumnResolver(entity, row) {
      if (entity === 'Employer') {
        return [`${row.Fnr || row.ShortID}`, row.Name]
      } else if (entity === 'Pensionable') {
        return [`${row.PersonalNumber.substring(0, 8)}-${row.PersonalNumber.substring(8, 12)}`, row.FullName]
      } else if (entity === 'EmployerContactPerson') {
        return [`${row.PersonalNumber.substring(0, 8)}-${row.PersonalNumber.substring(8, 12)}`, row.FullName]
      } else if (entity === 'Advisor') {
        return [`${row.ShortID} - ${row.Name}`, row.OrganizationName]
      } else if (entity === 'Account') {
        return [`${row.Number} - ${row.Name}`]
      } else if (entity === 'Transaction') {
        return [row.ShortID]
      } else if (entity === 'TransactionType') {
        return [`${row.InternalID} - ${row.Name}`]
      } else if (entity === 'Instrument') {
        return [row.Name]
      } else if (entity === 'Trust') {
        return [row.Name]
      } else if (entity === 'User') {
        return [`${row.FullName} <${row.Username}>`]
      } else if (entity === 'Comment') {
        return [`${row.Content.substring(0, 10)} [...]`]
      } else if (entity === 'AdvisorAgreement' && row.Type === 'employer-only') {
        return [row.EmployerName]
      } else if (entity === 'AdvisorAgreement' && row.Type === 'pensionable-only') {
        return [row.PensionableName]
      }
      return [row.ID.substring(0, 8)]
    },
    manyToOneColumns(field, value) {
      const relatedTo = `${field.RelatedTo}.ID`
      return [...Array(this.columnCount(field))].map((_, i) => {
        if (this.manyToOneColumnsResults[relatedTo] === undefined) {
          return '...'
        } else if (!this.manyToOneColumnsResults[relatedTo].has(value)) {
          return ''
        }
        return this.manyToOneColumnResolver(field.RelatedTo, this.manyToOneColumnsResults[relatedTo].get(value)[0])[i]
      })
    },
    oneToManyColumns(field, row) {
      let fKey = field.ForeignKey
      if (field.Type === 'polymorphic') {
        fKey += 'ID'
      }
      const relatedTo = `${field.Type === 'many-to-many' ? field.ManyToManyLinker : field.RelatedTo}.${fKey}`
      return [...Array(this.columnCount(field))].map((_, i) => {
        if (this.manyToOneColumnsResults[relatedTo] === undefined) {
          return '...'
        } else if (!this.manyToOneColumnsResults[relatedTo].has(row.ID)) {
          return ''
        }
        const related = this.manyToOneColumnsResults[relatedTo].get(row.ID)
        let suffix = ''
        if (related.length === 2) {
          suffix = ' och en annan'
        } else if (related.length > 2) {
          suffix = ` och ${related.length - 1} andra`
        }

        const m2mRel = `${field.RelatedTo}.ID`
        if (
          field.Type === 'many-to-many' &&
          this.manyToOneColumnsResults[m2mRel] &&
          this.manyToOneColumnsResults[m2mRel].has(related[0][field.ManyToManyForeignKey])
        ) {
          return (
            this.manyToOneColumnResolver(
              field.RelatedTo,
              this.manyToOneColumnsResults[m2mRel].get(related[0][field.ManyToManyForeignKey])[0]
            )[i] + suffix
          )
        }

        return (
          this.manyToOneColumnResolver(
            field.Type === 'many-to-many' ? field.ManyToManyLinker : field.RelatedTo,
            related[0]
          )[i] + suffix
        )
      })
    },
    refreshManyToOneColumnsResultsNow() {
      const commonsLinks = new Map()
      const trustLinks = new Map()

      const add = (group, relatedTo, foreignKey, val) => {
        const vals = group.get(`${relatedTo}.${foreignKey}`) || []
        if (vals.indexOf(val) === -1) {
          vals.push(val)
        }
        group.set(`${relatedTo}.${foreignKey}`, vals)
      }

      const m2mRelationHops = new Map()

      this.rows.forEach(row => {
        this.columns.forEach(column => {
          if (column.Type === 'many-to-one') {
            if (column.RelatedToCommon) {
              add(commonsLinks, column.RelatedTo, 'ID', row[column.Key])
            } else {
              add(trustLinks, column.RelatedTo, 'ID', row[column.Key])
            }
          } else if (column.Type === 'one-to-many') {
            if (column.RelatedToCommon) {
              add(commonsLinks, column.RelatedTo, column.ForeignKey, row.ID)
            } else {
              add(trustLinks, column.RelatedTo, column.ForeignKey, row.ID)
            }
          } else if (column.Type === 'polymorphic') {
            if (column.RelatedToCommon) {
              add(commonsLinks, column.RelatedTo, `${column.ForeignKey}ID`, row.ID)
            } else {
              add(trustLinks, column.RelatedTo, `${column.ForeignKey}ID`, row.ID)
            }
          } else if (column.Type === 'many-to-many') {
            m2mRelationHops.set(`${column.ManyToManyLinker}.${column.ForeignKey}`, {
              foreignPrimary: `${column.RelatedTo}.ID`,
              foreignKey: column.ManyToManyForeignKey,
              isCommon: column.RelatedToCommon
            })
            if (column.ManyToManyLinkerCommon) {
              add(commonsLinks, column.ManyToManyLinker, column.ForeignKey, row.ID)
            } else {
              add(trustLinks, column.ManyToManyLinker, column.ForeignKey, row.ID)
            }
          }
        })
      })

      const nullColumns = []

      // Clear out links that only got null values
      ;[trustLinks, commonsLinks].forEach(links => {
        links.forEach((val, key) => {
          const withoutNull = val.filter(v => v !== null)
          if (withoutNull.length === 0) {
            links.delete(key)
            nullColumns.push(key)
          } else {
            links.set(key, withoutNull)
          }
        })
      })

      const fetchList = (client, relatedTo, ids) => {
        const key = relatedTo.split('.')[0]
        const field = this.columns.find(c => c.RelatedTo === key)
        const allTrusts = this.allTrusts || field?.TrustFilter.Table

        // Unique ids
        ids = ids.filter((v, i, a) => a.indexOf(v) === i)

        const batches = []
        while (ids.length > 0) {
          batches.push(ids.splice(0, 20))
        }
        const relatedToParts = relatedTo.split('.')
        const results = batches.map(batch => {
          return client.listProvider(relatedToParts[0])({
            filters: new Map([[relatedToParts[1], batch.map(id => `==${id}`)]]),
            allTrusts
          })
        })

        return Promise.all(results).then(result => {
          const finalResult = new Map()

          result.forEach(batchResult => {
            batchResult.Results.forEach(row => {
              const rows = finalResult.get(row.Data[relatedToParts[1]]) || []
              rows.push(row.Data)
              finalResult.set(row.Data[relatedToParts[1]], rows)
            })
          })

          if (m2mRelationHops.has(relatedTo)) {
            const m2mRelation = m2mRelationHops.get(relatedTo)
            const m2mRelationClient = m2mRelation.isCommon
              ? this.$store.getters.api.common()
              : this.$store.getters.api.trust(this.$route.params.trust)
            return fetchList(
              m2mRelationClient,
              m2mRelation.foreignPrimary,
              [...finalResult.values()].flat(1).map(row => row[m2mRelation.foreignKey])
            ).then(result => {
              return [...result, [relatedTo, finalResult]]
            })
          }

          return [[relatedTo, finalResult]]
        })
      }

      const finalResultPromises = []

      commonsLinks.forEach((link, relatedTo) => {
        finalResultPromises.push(fetchList(this.$store.getters.api.common(), relatedTo, link))
      })
      trustLinks.forEach((link, relatedTo) => {
        finalResultPromises.push(fetchList(this.$store.getters.api.trust(this.$route.params.trust), relatedTo, link))
      })

      Promise.all(finalResultPromises).then(finalResults => {
        const allResults = {}
        finalResults.forEach(resultPairs => {
          resultPairs.forEach(([relatedTo, result]) => {
            allResults[relatedTo] = result
          })
        })
        this.manyToOneColumnsResults = allResults
        nullColumns.forEach(column => {
          this.manyToOneColumnsResults[column] = new Map()
        })
      })
    },
    toArrayTable(row, field) {
      return row[field.Key]
        .map(f => {
          return field.SubFields.map(subField => {
            let str = f[subField.Key]
            if (subField.Type === 'money') {
              str = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(str)
            }
            return str
          }).join(' ')
        })
        .join(' | ')
    },
    getSortKey(field) {
      return field.SortKey || field.Key
    }
  },
  mounted() {
    this.refreshColumns()
  },
  computed: {
    sortedColumns() {
      return [...this.columns].sort((a, b) =>
        !a.Order && !b.Order ? 0 : !a.Order ? 1 : !b.Order ? -1 : a.Order - b.Order
      )
    },
    sortables() {
      return new Map(this.columns.filter(c => c.Sortable).map(c => [c.Key, c]))
    },
    filterables() {
      return new Map(this.columns.filter(c => c.Filterable).map(c => [c.Key, c]))
    },
    stateColors() {
      var states = new Map()
      this.columns.forEach(col => {
        if (col.Type !== 'state') {
          return
        }
        col.Choices.forEach(choice => {
          if (choice.Color) {
            let c = choice.Color
            if (c === 'red') {
              c = '#FAE5E5'
            } else if (c === 'green') {
              c = 'rgba(0, 255, 7, 0.25)'
            } else if (c === 'blue') {
              c = 'rgba(0, 114, 255, 0.25)'
            } else if (c === 'yellow') {
              c = 'rgba(255, 247, 0, 0.25)'
            } else if (c === 'gray') {
              c = 'rgba(0, 0, 0, 0.25)'
            }
            states.set(choice.Value, c)
          }
        })
      })

      return states
    },
    filtersAsList() {
      return this.filtersChangeTracker && Array.from(this.filters)
    },
    providerCtx() {
      return {
        uniqueid: this.uniqueid,
        filters: this.filters,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
    },
    totalColumnCount() {
      if (!this.columns) {
        return 0
      }
      return this.columns.reduce((p, field) => p + this.columnCount(field), 0)
    },
    rowLink() {
      return {
        key: this.rowLinkField?.Key ?? this.primaryKeyField.Key,
        type: this.rowLinkField?.RelatedTo
      }
    }
  },
  watch: {
    'providerCtx.filters'() {
      this.loading = true
      this.refresh()
    },
    'providerCtx.sortBy'() {
      this.loading = true
      this.refreshNow()
    },
    'providerCtx.sortDesc'() {
      this.loading = true
      this.refreshNow()
    },
    listProvider() {
      this.sortBy = null
      this.sortDesc = false

      this.pageInfo = null

      this.loading = true
      this.refreshNow()
    },
    fieldsProvider() {
      this.refreshColumns()
    },
    rows(rows) {
      this.refreshManyToOneColumnsResults()
      this.$emit('rows-updated', rows)
    }
  }
})
</script>

<style>
.jsondiffpatch-unchanged:not([data-key='ID']):not([data-key='ShortID']) {
  display: none;
}
</style>
<style scoped lang="scss">
//@import url("//hello.myfonts.net/count/390b0b");
@font-face {
  font-family: 'Nexa';
  font-weight: 700;
  src: url('~@/assets/nexa/390B0B_0_0.eot');
  src: url('~@/assets/nexa/390B0B_0_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_0_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_0_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nexa';
  font-weight: 400;
  src: url('~@/assets/nexa/390B0B_1_0.eot');
  src: url('~@/assets/nexa/390B0B_1_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_1_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_1_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_1_0.ttf') format('truetype');
}

@import '~bulma/sass/utilities/_all';

// Set variables to overwrite Buefy
$family-sans-serif: 'Arial', sans-serif;

// Set your colors
$deep-sapphire: #012169;
$lochmara: #0085ca;
$calypso: #34657f;
$silver-chalice: #a7a8aa;

$primary: $deep-sapphire;
$primary-invert: findColorInvert($primary);
$info: $lochmara;
$info-invert: findColorInvert($info);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'light': (
    $light,
    $light-invert
  ),
  'dark': (
    $dark,
    $dark-invert
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import '~bulma';

.w-50 {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}

.w-75 {
  width: 75px;
  max-width: 75px;
  min-width: 75px;
}

.w-100 {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.w-150 {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.w-200 {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.w-50 span,
.w-75 span,
.w-100 span,
.w-150 span,
.w-200 span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message-container {
  top: 1.5rem;
  left: 50%;
  position: fixed;
  z-index: 10;
  background-color: #012169;
  color: white;
  padding: 0.5rem 1.5rem;
  transform: translate(-50%, 0);
}

.dynamic-table {
  overflow-y: hidden;
  overflow-x: auto;
  max-height: 100vh;
}

.filter th > * {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sortable th {
  cursor: pointer;
  white-space: nowrap;
}

th:last-child {
  width: 100%;
}

.button--unstyled {
  background: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: block;
  cursor: pointer;
  &:active {
    color: inherit;
  }
}

.filter-container {
  display: flex;
}

.filter-bubble {
  border-radius: 1em;
  font-family: CircularStd;
  padding: 0px 1.25em;
  font-size: 14px;
  border-width: 0;
  text-align: center;
  position: relative;
  display: inline-flex;
  &:hover {
    button {
      opacity: 1;
      pointer-events: auto;
    }
  }
  input {
    background: transparent;
    border-width: 0;
    font-size: 14px;
    line-height: 1rem;
    outline: none;
  }
  svg {
    width: 14px;
    height: 14px;
    display: block;
  }
  button {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
  }
  &--green {
    background-color: var(--green);
  }
  &--red {
    background-color: var(--red);
  }
  & + .filter-bubble {
    margin-left: 3px;
  }
}

.button-add-filter {
  padding: 3px 0px;
  opacity: 0;
  pointer-events: none;
  margin-left: 3px;
  svg {
    width: 14px;
    height: 14px;
    display: block;
  }
}
.button-add-new {
  &.fixed {
    position: fixed;
    bottom: 0;
  }
  left: var(--navigation-total-width);
  width: calc(100% - var(--navigation-total-width));
  background: var(--main-color);
  color: #fff;
  border-width: 0;
  padding: 1.5rem 1rem;
  transform: translateY(calc(100% - 15px));
  transition: transform var(--default-transition-duration) var(--ease);
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    transform: translateY(0%);
  }
}

.button-add-new-inline {
  width: 100%;
  border-width: 0;
  padding: 0.3rem 1rem;
  cursor: pointer;
  margin-top: 0.5rem;
  border: 1px dashed #ddd;
  color: #ddd;
  font-weight: 700;
  font-size: 20px;
  border-radius: 0;
  background: white;
  &:hover {
    border: 1px dashed #666;
    color: #666;
  }
}

tr.filter th:hover .button-add-filter {
  opacity: 1;
  pointer-events: auto;
}

/* Possible loading state for data */
table td span {
  position: relative;
}
table td span::before {
  transition: transform 100ms cubic-bezier(0.5, 0, 0.5, 1);
  transform: scaleX(0);
  transform-origin: left center;
  content: '';
}
table.loading td {
  user-select: none;
}
table.loading td span::before {
  content: '';
  width: calc(100% + 15px);
  height: 100%;
  background: var(--silver);
  position: absolute;
  top: 0;
  left: -5px;
  border-radius: 500px;
  transform: scaleX(1);
}

.split-percentage {
  margin-left: 0.25em;
  color: #aaa;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-status-bar {
  height: 5em;
  div {
    position: absolute;
    left: 50vw;
    transform: translate(-50%, 0);

    span::before {
      background: none !important;
    }
  }
}
.loading-spinner {
  svg {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.comment-content {
  display: block;
  max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
