<template>
  <div
    class="field field--text"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <masked-input
      type="money"
      suffix=" kr"
      :disabled="disabled"
      :placeholder="placeholder"
      :filter="onlyDigits"
      :confirmation="true"
      :selectOnFocus="true"
      v-model="cValue"
      @nextInput="$emit('nextInput')"
    />
  </div>
</template>

<script>
import maskedInput from '@/components/maskedInput.vue'

export default {
  name: 'field-money',
  components: {
    maskedInput
  },
  props: {
    error: String,
    grid: Object,
    disabled: Boolean,
    id: String,
    name: String,
    placeholder: String,
    type: String,
    disallowNegative: {
      type: Boolean,
      default: false
    },
    disallowDecimals: {
      type: Boolean,
      default: false
    },
    value: [String, Number, null]
  },
  data() {
    return {
      masked: true
    }
  },
  computed: {
    cValue: {
      get() {
        if (this.value === '0') return ''
        return this.value || ''
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onlyDigits(char) {
      let valid = char.match(/[0-9]/)
      if (!this.disallowDecimals) {
        valid = valid || char === '.' || char === ','
      }

      if (!this.disallowNegative) {
        valid = valid || char === '-'
      }

      return valid
    }
  }
}
</script>
