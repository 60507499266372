<template>
  <div
    class="input-container"
    :class="{
      'confirmation-required': this.confirmation
    }"
  >
    <input
      v-bind="$attrs"
      @focus="onFocus"
      @blur="onBlur"
      @keypress="characterAllowed"
      @paste="valueAllowed"
      v-model="cValue"
      @keydown.enter.prevent="onEnter"
      ref="input"
    />
    <button v-if="confirmationRequired" @mousedown.prevent="onEnter">OK</button>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'masked-input',
  props: {
    value: [String, Number, null],
    type: String,
    suffix: String,
    filter: Function,
    selectOnFocus: Boolean,
    confirmation: Boolean // requires the user to confirm the value
  },
  data() {
    return {
      isMasked: true,
      masked: '',
      money: Number,
      raw: '',
      emitValue: null,
      confirmed: true
    }
  },
  computed: {
    cEmitValue: {
      get() {
        return this.emitValue
      },
      set(value) {
        this.emitValue = value
        if (!this.confirmation) {
          this.$emit('input', this.emitValue)
        } else {
          this.confirmed = this.confirmed && value === this.value
        }
      }
    },
    cValue: {
      get() {
        return this.isMasked ? this.masked : this.raw
      },
      set(value) {
        // If value is null, undefined, set the raw value to empty
        if (value === null || value === undefined) {
          this.$set(this, 'raw', '')
          this.masked = ''
          this.cEmitValue = null
          return
        }

        this.$set(this, 'raw', '' + value) // Set the raw value to a string
        this.masked = this.maskValue(this.raw)

        // if the type is money, emit the money value
        const isNegative = this.raw.startsWith('-')
        switch (this.type) {
          case 'money':
            if (this.masked === false) {
              this.masked = 'Ogiligt värde'
              this.money = null
              break
            }

            if (this.raw !== '-') {
              this.money = Number(this.masked.replace(',', '.').replace(/[^0-9.]/g, '')) // Parse the masked value to a number
              if (isNegative) {
                this.money = -this.money
              }
            } else {
              this.money = '-'
            }

            this.cEmitValue = this.money
            break
          case 'masked':
          case 'date':
            this.cEmitValue = this.masked
            break
          default:
            this.cEmitValue = this.raw
        }
      }
    },
    confirmationRequired() {
      return !this.isMasked && this.confirmation && !this.confirmed
    }
  },
  methods: {
    getInput() {
      return this.$refs.input
    },
    characterAllowed(e) {
      // If there's a filter, use it
      if (this.filter) {
        // Remove any selected text
        const value = this.raw.replace(e.target.value.substring(e.target.selectionStart, e.target.selectionEnd), '')
        // Check if the character is allowed
        const allowed = this.filter(e.key, value)
        if (!allowed) {
          e.preventDefault()
          return false
        }
      }
      return true
    },
    valueAllowed(e) {
      if (this.filter) {
        const pasteContent = (e.clipboardData || window.clipboardData).getData('text')
        // Remove any selected text
        const value = this.raw.replace(e.target.value.substring(e.target.selectionStart, e.target.selectionEnd), '')
        for (const char of pasteContent) {
          const allowed = this.filter(char, value)
          if (!allowed) {
            e.preventDefault()
            return false
          }
        }
      }
    },
    onFocus() {
      this.isMasked = false
      this.$emit('focus')

      if (this.selectOnFocus) {
        this.$nextTick(() => {
          this.getInput().select()
        })
      }
    },
    onBlur() {
      this.isMasked = true
      this.$emit('blur')
    },
    onEnter() {
      if (!this.confirmation) return
      this.confirmed = true
      this.$emit('nextInput') // Must be called before blur
      this.$refs.input.blur()
    },
    maskValue(value) {
      const toMoney = v => {
        const isNegative = v.startsWith('-')
        v = v.replace(',', '.').replace(/[^0-9.]/g, '') // replace comma (,) with decimal (.)
        var digits = v.includes('.') && v[v.length - 1] !== '.' ? 2 : 0 // check if the value has a decimal (.)
        if (v === '-') return v // if the value is a minus (-), return it
        if (_.isNaN(Number(v))) return false // if the value is not a number, return false
        return Number(isNegative ? -v : v).toLocaleString('sv-SE', {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
          roundingMode: 'trunc'
        })
      }

      const toDate = v => {
        // Remove all non-digits
        v = v.replace(/[^0-9]/g, '')
        if (v.length === 4) {
          // Add entire year
          v = `${new Date().getFullYear()}${v}`
        }

        if (v.length === 6) {
          // Add prefix of current year if necessary
          // if the year with 20 prefix is greater than the current year + 1, use 19 prefix, else use 20 prefix
          const prefix = `${this.$moment()
            .format('YYYY')
            .substr(0, 2)}`
          v = `${prefix}${v}`
        }

        // Convert number to masked date
        if (v.length === 8) {
          return `${v.substr(0, 4)}-${v.substr(4, 2)}-${v.substr(6, 2)}`
        }

        return v
      }

      // Convert value to string
      const v = String(value)
      let masked = ''
      switch (this.type) {
        case 'money':
          masked = toMoney(v)
          if (masked === false) {
            return false
          }
          break
        case 'date':
          masked = toDate(v)
          if (masked === false) {
            return false
          }
          break
        default:
          masked = v
      }

      // Add suffix
      if (this.suffix) {
        masked += this.suffix
      }

      return masked
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        // If the value is un-masked, or if the value is the same after masking, don't update
        if (!this.isMasked || this.maskValue(value) === this.masked) {
          return
        }
        this.cValue = value
      }
    },
    isMasked() {
      if (!this.confirmation) return
      if (this.confirmed) {
        this.$emit('input', this.emitValue) // Emit new value
      } else {
        this.confirmed = true // Reset confirmation
        this.cValue = this.value // Reset value
      }
    }
  }
}
</script>

<style scoped>
.input-container {
  position: relative;
  display: inline-block;
}
.input-container.confirmation-required > input {
  padding-right: 3rem;
}
button {
  position: absolute;
  right: 1px;
  top: 1px;
  height: calc(100% - 2px);
  border: none;
  border-radius: 0 4px 4px 0;
  background-color: var(--main-color);
  color: #fff;
  padding: 0 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
}
button:hover {
  background-color: var(--blue-dark);
}
button:active {
  background-color: var(--blue-dark);
}
</style>
