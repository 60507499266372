<template>
  <div
    class="field field--text"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <span class="field__label">
      <label :for="id">
        <slot></slot>
      </label>
      <button v-if="!disabled && !readonly && value !== null" @click.prevent="clear">Rensa</button>
    </span>
    <span style="display: flex;" :class="dateClass">
      <masked-input
        type="date"
        ref="input"
        placeholder="20xx-xx-xx"
        :disabled="disabled || readonly"
        v-model="cValue"
        :filter="maxDateLength"
        @keydown.native.enter.prevent="onEnter"
        @focus="onFocus"
        @blur="onBlur"
      />
      <input
        v-if="type === 'datetime'"
        type="time"
        ref="time"
        :value="pickedTime"
        :disabled="disabled || readonly"
        @input="updateTime($event.target.value)"
        @keydown.enter.prevent="onEnter"
        @focus="onFocus"
        @blur="onBlur"
      />
    </span>
    <small v-if="error" style="color: red; font-style: italic">{{ error }}</small>
    <small v-if="!validDate && required" style="color: red; font-style: italic">Ogiltigt datum</small>
  </div>
</template>

<script>
import 'vue-datetime/dist/vue-datetime.css'
import maskedInput from '@/components/maskedInput.vue'

export default {
  name: 'field-datetime',
  props: {
    error: String,
    grid: Object,
    disabled: Boolean,
    id: String,
    name: String,
    placeholder: String,
    readonly: Boolean,
    required: Boolean,
    type: String,
    showtime: Boolean,
    value: String
  },
  data() {
    return {
      dateClass: 'valid'
    }
  },
  computed: {
    cValue: {
      get() {
        return this.pickedDate
      },
      set(value) {
        this.update(value)
      }
    },
    pickedDateTime() {
      return this.value !== null ? this.$moment(this.value) : null
    },
    pickedDate() {
      return this.value !== null ? this.$moment(this.value).format('YYYY-MM-DD') : null
    },
    pickedTime() {
      return this.value !== null ? this.$moment(this.value).format('HH:mm:ss') : null
    },
    validDate() {
      return this.$moment(this.value).isValid()
    }
  },
  watch: {
    value() {
      this.dateClass = 'valid'
    }
  },
  methods: {
    getInput() {
      return this.$refs.input.$el.querySelector('input')
    },
    clear() {
      this.update(null)
      // Focus field
      this.$nextTick(() => {
        this.getInput().focus()
      })
    },
    update(newDate) {
      this.dateClass = newDate == null || newDate.length === 0 ? 'empty' : 'writing'
      if (newDate === null || newDate.length === 0) {
        this.$emit('input', null)
        return
      }
      // if the 3rd character is a dash, prepend the first 2 characters of the current year
      if (newDate !== null && newDate.substr(2, 1) === '-') {
        newDate = `${this.$moment()
          .format('YYYY')
          .substr(0, 2)}${newDate}`
      }

      if (newDate !== null && newDate.length !== 10) {
        this.$emit('input', null)
        return
      }
      const isValid = this.$moment(`${newDate}T00:00:00Z`).isValid()
      this.dateClass = isValid ? 'valid' : 'invalid'
      if (!isValid) {
        return
      }
      if (newDate !== null) {
        newDate = this.$moment(`${newDate}T00:00:00Z`).toISOString()
      }
      this.$emit('input', newDate)
    },
    updateTime(newTime) {
      if (this.value === null) {
        return
      }
      var newValue = this.$moment(this.value)
      newValue.set('hour', newTime.substr(0, 2))
      newValue.set('minute', newTime.substr(3, 2))
      newValue.set('second', newTime.substr(6, 2))
      this.$emit('input', newValue.toISOString())
    },
    onBlur() {
      if (this.value === null && this.required) {
        this.update(this.$moment().format('YYYY-MM-DD'))
      }
    },
    onEnter() {
      // If no date is selected, set it to today
      if (this.value === null && this.required) {
        this.update(this.$moment().format('YYYY-MM-DD'))
      }
      this.$emit('nextInput')
    },
    onFocus() {
      // Select all text in the input
      this.getInput().select()
    },
    maxDateLength(char, value) {
      // Only allow digits and dashes
      // If the value contains dashes, it should be a max of 10 characters
      // If the value does not contain dashes, it should be a max of 8 characters
      if (char.match(/[0-9-]/) && (char === '-' || value.indexOf('-') === -1 ? value.length < 8 : value.length < 10)) {
        return true
      }

      return false
    }
  },
  components: {
    maskedInput
  }
}
</script>

<style scoped>
button {
  color: white;
  background-color: #ff6c6c;
  border-radius: 6px;
  border: 0;
  margin-left: 1rem;
}
.invalid input:first-child {
  border: 1px solid red;
}
.writing input:first-child {
  border: 1px solid yellow;
}
</style>
